.customContext {
  display: none;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 50;
}
.customContext a {
  color: #333;
  text-decoration: none;
  cursor: pointer;
}
.customContext a:hover {
  color: #f0f0f0;
}

.customContext .menu,
.customContext .submenu {
  list-style-type: none;
  padding: 0;
  box-shadow: 0 2px 12px 0 #0000001a;
}

.customContext .menuItem {
  padding: 8px;
  position: relative;
  border-left: 0.5px solid #333;
  border-right: 0.5px solid #333;
  background-color: #fff;
  color: #333;
}
.customContext .menuItem .label {
  display: flex;
  align-items: center;
}
.customContext .menuItem:first-child {
  border-top: 0.5px solid #333;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.customContext .menuItem:last-child {
  border-bottom: 0.5px solid #333;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.customContext .menuItem:hover {
  color: #f0f0f0;
  background-color: #515d57;
}
.menuItem.has-submenu > .submenu .menuItem {
  color: #333; /* Reset background for sub-menu items */
  background-color: #fff;
}
.menuItem.has-submenu > .submenu .menuItem:hover {
  background-color: #515d57;
  color: #f0f0f0;
}
.customContext .menuItem.has-submenu:last-child:hover {
  border-right: none;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.customContext .submenu {
  display: none;
  position: absolute;
  left: 100%;
  top: -1px;
  border-left: none;
}
.customContext .submenu .menuItem:first-child {
  border-left: none;
  border-top-left-radius: 0px;
}
.customContext .submenu .menuItem:only-child {
  border-bottom-left-radius: 0px;
}

.customContext .menuItem.hasSubmenu:hover > .submenu {
  display: block; /* Show on hover */
}
