.userMenu {
  position: absolute;
  top: 10px;
  left: 10px;
}

.userMenu > a {
  font-size: 0.8rem;
  color: #f0f0f0;
  cursor: pointer;
  border: 1px solid #f0f0f0;
  border-radius: 15px;
  padding: 15px;
  position: relative;
  top: 10px;
  background-color: #1b2828;
  filter: drop-shadow(2px 2px 2px #1a1a1a);
}
.userMenu > a:hover {
  color: #fff;
  background-color: #131c1c;
}

.menuContainer {
  position: absolute;
  text-align: left;
  z-index: 40;
}

.userMenu .menuContainer .icon {
  text-align: center;
  border: 1px solid #fff;
  border-radius: 50%;
  width: 35.5px;
  height: 32.5px;
  display: inline-block;
}

.userMenu .menuContainer ul {
  text-align: center;
  list-style: none;
  color: #fff;
  border-top: 1px solid #333;
  border-left: 1px solid #333;
  border-right: 1px solid #333;
  font-size: 0.8rem;
  padding: 0;
  margin: 0;
  margin-top: 5px;
}
.userMenu .menuContainer ul li {
  border-bottom: 1px solid #333;
  width: 100px;
  background-color: #515d57;
  cursor: pointer;
  position: relative;
  padding: 5px;
}
.userMenu .menuContainer ul li:hover {
  background-color: #40554b;
}
