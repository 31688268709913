.sidebar-container {
  position: fixed;
  right: -3px;
  top: 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  color: #999;
  z-index: 20;
}

.sidebar-container .ps-menu-root,
.sidebar-container .ps-menu-root ul {
  height: 100%;
}

.sidebar-container .ps-sidebar-root {
  height: 100vh;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  border-left: none !important;
}
.sidebar-container .ps-sidebar-container {
  background-color: #515d57;
}
.sidebar-container .ps-menuitem-root {
  border-radius: 15px;
  box-sizing: border-box;
  width: 90%;
  margin: 5px 0px 5px 0px;
  border-radius: 15px;
  border: 1px solid #333;
}
.sidebar-container .ps-menuitem-root a {
  cursor: grab;
}

.sidebar-container [data-rbd-droppable-id="droppable"] {
  text-align: left;
  height: 100%;
}
.sidebar-container .ps-menuitem-root a button:hover {
  background: #0f1e1e;
  border: 1px dashed;
}
.sidebar-container .ps-menuitem-root.active a {
  color: #fff;
  font-weight: bold;
  border: 1px dashed #fff;
  border-radius: 15px;
  background-color: #393131;
}
.sidebar-container .ps-menuitem-root.active a:hover {
  color: #fff;
  font-weight: bold;
  border: 1px dashed #fff;
  border-radius: 15px;
  background-color: #393131;
  opacity: 0.8;
}
.sidebar-container .ps-menuitem-root:not(.active) a:hover {
  background-color: #40554b;
  border-radius: 15px;
  border: 1px dashed #333;
  cursor: pointer;
  color: #fff;
}
.sidebar-container .ps-menuitem-root:not(.active):hover .menu-icon {
  color: #fff;
}
.sidebar-container .ps-menuitem-root .menu-icon {
  color: #999;
}
.sidebar-container .ps-menuitem-root.active .menu-icon {
  color: #fff;
}

.sidebar-container .ps-menuitem-root .ps-menu-label {
  display: flex;
  align-items: center;
  overflow: revert;
  justify-content: space-around;
}
.sidebar-container .ps-menuitem-root .ps-menu-label .chapter-text {
  font-size: 0.8em;
  width: 150px;
  display: inline-block;
  text-overflow: ellipsis;
  padding-right: 10px;
  text-align: left;
  overflow: hidden;
  padding-left: 10px;
}

.sidebar-container .ps-rtl {
  border-left: none;
}
.sidebar-container .handle {
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  font-size: 0.8rem;
  background-color: #515d57;
  border-left: 1px solid #efefef;
  padding: 10px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  writing-mode: vertical-lr;
  width: 40px;
  cursor: pointer;
  color: #efefef;
}
.sidebar-container .handle:hover {
  color: #fff;
}

.sidebar-container .button-container {
  position: sticky;
  bottom: 0px;
  background-color: #515d57;
  border-top: 1px solid #999;
  padding-top: 20px;
}

.sidebar-container .button-container .sidebar-add-new {
  border: 1px solid #efefef;
  margin: 15px;
}
.sidebar-container .button-container .sidebar-add-new:hover {
  background-color: #0f1e1e;
}

.sidebar-container [data-rbd-droppable-id="droppable"] {
  height: initial;
  min-height: calc(100% - 90px);
}
