.editSeriesModal .content {
  display: flex;
}
.editSeriesModal .column {
  flex: 1;
}
.editSeriesModal .column p,
.editSeriesModal .seriesBox p {
  margin-top: 0px;
}
.editSeriesModal .column.left {
  width: 200px;
  margin-right: 10px;
}
.editSeriesModal .column.right {
  padding: 10px;
  padding-top: 0px;
  text-align: center;
}

.editSeriesModal .column.right label {
  text-align: left;
  display: block;
}
.editSeriesModal .column.right input {
  font-size: 1rem;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0.5px 0.5px 1px 0.5px #333;
  border: none;
}
.editSeriesModal .column.right textarea {
  display: block;
  width: 100%;
  height: 150px;
  line-height: 1.5em;
  font-family: inherit;
  resize: none;
  border-radius: 10px;
  box-shadow: 0.5px 0.5px 1px 0.5px #333;
  border: none;
  padding: 10px;
}
.editSeriesModal .seriesBox {
  padding: 8px;
}
.editSeriesModal .seriesBox label {
  margin-left: 10px;
}

.editSeriesVolumes {
  list-style: none;
  padding-left: 10px;
  font-size: 0.9rem;
  box-sizing: initial;
}
.editSeriesModal .addStory > svg {
  font-size: 1.2em !important;
}
.editSeriesVolumes > div {
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.editSeriesVolumes > div:hover {
  cursor: grab;
  color: #151515;
}
.editSeriesVolumes .storyButtons {
  margin-left: auto;
}
.editSeriesVolumes .storyButtons svg {
  box-sizing: initial;
}

.editSeriesVolumes .seriesIcon {
  width: 50px;
  text-align: right;
}

.editSeriesVolumes img {
  max-height: 50px;
  vertical-align: middle;
  border-radius: 5px;
  border: 1px solid #333;
}

.editSeriesVolumes span {
  margin-left: 10px;
}

@media only screen and (max-width: 600px) {
  .editSeriesModal .content {
    flex-direction: column;
  }
  .editSeriesModal .column.left {
    margin: 0 auto;
    margin-bottom: 10px;
  }
  .editSeriesModal .column.right input {
    width: 100%;
  }
}
