.storyForm .content {
  display: flex;
}
.storyForm .column {
  flex: 1;
}
.storyForm .column p,
.storyForm .seriesBox p {
  margin-top: 0px;
}
.storyForm .column.left {
  width: 200px;
  margin-right: 10px;
}
.storyForm .column.right {
  padding: 10px;
  padding-top: 0px;
  text-align: center;
}

.storyForm .column.right label {
  text-align: left;
  display: block;
}
.storyForm .column.right input {
  font-size: 1rem;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0.5px 0.5px 1px 0.5px #333;
  border: none;
}
.storyForm .column.right textarea {
  display: block;
  width: 100%;
  height: 150px;
  line-height: 1.5em;
  font-family: inherit;
  resize: none;
  border-radius: 10px;
  box-shadow: 0.5px 0.5px 1px 0.5px #333;
  border: none;
  padding: 10px;
}
.storyForm .seriesBox {
  padding: 8px;
}
.storyForm .seriesBox > div {
  margin-bottom: 10px;
}
.storyForm .seriesBox input[type="checkbox"] {
  margin-right: 10px;
}

@media only screen and (max-width: 600px) {
  .storyForm .content {
    flex-direction: column;
  }
  .storyForm .column.left {
    margin: 0 auto;
    margin-bottom: 10px;
  }
  .storyForm .column.right input {
    width: 100%;
  }
}
