.subscribe .changePaymentButton {
  margin-left: 10px;
}
.subscribe .productDescription {
  margin-bottom: 20px;
}
.subscribe .stripeCardInput {
  padding: 10px;
  border: 1px solid gray;
  border-radius: 15px;
}
