.associationUIBG {
  z-index: 60;
}

.associationUIContainer {
  width: 80%;
  height: 80%;
  background-color: #445c66;
  border-radius: 15px;
  filter: drop-shadow(4px 4px 4px #1a1a1a);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: hidden;
  overflow-y: scroll;
  color: #bbb;
  padding: 10px;
}

.associationUIContainer h1 {
  font-size: 1.5em;
  font-weight: normal;
  display: inline;
  margin-right: 5px;
  margin-top: 0.67em;
  margin-bottom: 0.67em;
}

.associationUIContainer .inlineEditableText {
  text-overflow: ellipsis;
  margin-top: 0.67em;
  margin-bottom: 0.67em;
}

.associationUIContainer .multilineEditableText {
  line-height: 1.5em;
  outline: none;
}

.associationUIContainer .column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

.associationUIContainer .associationDetails {
  text-align: left;
  width: 90%;
}

.associationUIContainer .associationDetails .detailBubble {
  margin-top: 10px;
  margin-bottom: 20px;
}

.associationUIContainer .associationDetails .detailBubble .docBG {
  border: 1px solid #000;
  color: #bbb;
  border-radius: 10px;
  max-height: 300px;
  overflow-y: auto;
  padding: 15px;
}

@media only screen and (max-width: 600px) {
  .associationUIContainer h1 {
    font-size: 1em;
  }

  .associationUIContainer .inlineEditableText {
    font-size: 1em;
    width: 60%;
  }

  .associationUIContainer .portrait {
    margin-bottom: 0;
    font-size: 0.8rem;
  }

  .associationUIContainer .portrait img {
    width: 100%;
  }

  .associationUIContainer {
    flex-direction: column;
    flex-wrap: nowrap;
  }

  .associationUIContainer .column {
    flex-direction: row;
  }

  .associationUIContainer .associationDetails {
    width: 100%;
  }

  .associationUIContainer .associationDetails .detailBubble {
    margin-right: 10px;
    margin-left: 10px;
  }

  .associationUIContainer .associationForm {
    margin-left: 10px;
    margin-right: 10px;
    padding: 15px;
  }
}