.splash {
  margin-top: 100px;
  color: #333;
}

.splash .blurb {
  background-color: azure;
  width: 60%;
  margin: 0 auto 50px;
  border-radius: 15px;
  filter: drop-shadow(2px 2px 2px #1a1a1a);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 50px;
}

.splash .blurb .column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

.splash .blurb .column.leftText {
  text-align: left;
}

.splash .blurb .column img {
  max-height: 350px;
  border-radius: 15px;
  border: 1px solid #333;
  filter: drop-shadow(2px 2px 2px #1a1a1a);
}

.splash .blurb video {
  width: 500px;
  border-radius: 15px;
  border: 1px solid #333;
  filter: drop-shadow(2px 2px 2px #1a1a1a);
}

@media only screen and (max-width: 600px) {
  .splash .blurb {
    width: initial;
    margin-left: 10px;
    margin-right: 10px;
    padding: 10px;
    flex-direction: column;
  }
  .splash .blurb video {
    width: 100%;
    margin-top: 20px;
  }
  .splash .blurb figure {
    margin: 0;
  }
  .splash .blurb img {
    width: 100%;
  }
  .splash .blurb .column h2,
  .splash .blurb .column p {
    margin: 0;
  }
}
