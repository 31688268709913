.editorContainer {
  width: 210mm;
  margin: 0 auto;
  max-height: '100%';
  overflow: hidden;
}

.editorBG {
  border: 1px solid #000;
  border-radius: 10px;
  background-color: #f0f0f0;
  margin: 10px auto;
  color: #000;
  filter: drop-shadow(2px 2px 2px #1a1a1a);
  min-height: 400px;
  padding: 1in;
  padding-top: 40px;
}

.titleInfo {
  color: #fff;
  margin-top: 65px;
}

.titleInfo span {
  display: inline-block;
  padding: 10px;
}

.titleInfo span:hover {
  border: 1px solid #333;
  border-radius: 10px;
}

.titleInfo h2 {
  filter: drop-shadow(2px 2px 2px #1a1a1a);
  margin-bottom: 0;
}

.titleInfo h3 {
  margin: 0;
}

/*padding: 0.75rem 1.25rem;*/
@media only screen and (max-width: 600px) {
  .titleInfo h3 {
    margin: 5px;
  }

  div.DraftEditor-root {
    margin-left: 5px;
  }

  div.DraftEditor-editorContainer {
    padding: 0;
    width: calc(95% - 20px);
    user-select: none;
  }

  .editorContainer {
    width: inherit;
  }

  .editorBG {
    padding: 20px;
    padding-right: 40px;
  }
}