.associationTooltipBody {
  width: 300px;
  display: inline-block;
}

.associationTooltipBody .column {
  width: 50%;
  vertical-align: top;
  padding: 5px;
  text-align: center;
  min-height: 0;
  box-sizing: border-box;
  float: left;
}
.associationTooltipBody .column:last-child {
  text-align: left;
  height: 90%;
  overflow: hidden;
  max-height: 120px;
  padding-right: 15px;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
}
.associationTooltipBody .column img {
  filter: drop-shadow(1px 1px 1px #1a1a1a);
}
