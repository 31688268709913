nav.richControls {
  width: 210mm;
  display: inline-block;
  text-align: left;
  padding: 10px;
  background: #545e55;
  border-radius: 10px;
  filter: drop-shadow(2px 2px 2px #1a1a1a);
  z-index: 10;
  position: sticky;
  top: 10px;
  line-height: 40px;
}

nav.richControls:not(:hover) {
  opacity: 0.8;
  transition: opacity 0.2s ease-in-out;
}
nav.richControls:hover {
  opacity: 0.95;
  transition: opacity 0.2s ease-in-out;
}

nav.richControls button {
  text-align: center;
  margin: 0px 5px 0px 5px;
  font-size: 1em;
  padding: 5px;
  width: 34px;
  cursor: pointer;
  border-radius: 5px;
  border: none;
  filter: drop-shadow(2px 2px 2px #1a1a1a);
}
nav.richControls button.active {
  filter: none;
  border: 1px solid black;
  background-color: gray;
}

nav.richControls .rightControls {
  float: right;
}
nav.richControls .rightControls > div {
  margin-right: 10px;
}

nav.richControls .exportOption {
  width: auto;
}

nav.richControls .hiddenControlsRow {
  overflow: hidden;
  height: 0;
  transition: height 0.3s ease-out;
  width: 100%;
}

nav.richControls .hiddenControlsRow.active {
  height: auto;
  padding: 5px;
}
nav.richControls .hiddenControlsRow label {
  color: #fff;
  vertical-align: middle;
}
nav.richControls .hiddenControlsRow label input {
  margin-left: 5px;
  margin-right: 10px;
}

nav.richControls .hiddenControlsRow .docterBtn {
}

@media only screen and (max-width: 600px) {
  nav.richControls {
    height: initial;
    width: calc(100% - 60px);
    opacity: 1;
    margin-left: -50px;
  }
  nav.richControls:not(:hover) {
    opacity: 1;
  }
  nav.richControls .hiddenControlsRow > span {
    margin-top: 10px;
    margin-right: initial;
  }
  nav.richControls .hiddenControlsRow.active {
    padding: 0px;
  }
}
